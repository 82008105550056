<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="融媒体名称">
            <el-input v-model="searchForm.mediaName" placeholder="请输入 融媒体名称"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button @click="dialogFormVisible = true; title = '新增'" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="融媒体名称" prop="mediaName" :rules="[{ required: true, message: '请输入 融媒体名称' }]">
              <el-input clearable v-model="form.mediaName" placeholder="请输入 融媒体名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item label="融媒体地址" prop="mediaAddr" :rules="[{ required: true, message: '请输入 融媒体地址' }]">
                <el-input v-model="form.mediaAddr" placeholder="请输入 融媒体地址" autocomplete="off"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="融媒体类型" prop="typeStr" :rules="[{ required: true, message: '请输入 融媒体类型' }]">
              <el-input v-model="form.typeStr" placeholder="请输入 融媒体类型" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="优先级" prop="num" :rules="[{ required: true, message: '请输入 优先级' }]">
              <el-input-number v-model="form.num" :min="1" :max="10000"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="融媒体描述" prop="msg">
              <el-input clearable v-model="form.msg" placeholder="请输入 融媒体描述" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSave">提交</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
        append-to-body
        v-model="editFormVisible"
        title="编辑"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="融媒体名称" prop="mediaName" :rules="[{ required: true, message: '请输入 融媒体名称' }]">
              <el-input clearable v-model="form.mediaName" placeholder="请输入 融媒体名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="融媒体地址" prop="mediaAddr" :rules="[{ required: true, message: '请输入 融媒体地址' }]">
              <el-input v-model="form.mediaAddr" placeholder="请输入 融媒体地址" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="融媒体类型" prop="typeStr" :rules="[{ required: true, message: '请输入 融媒体类型' }]">
              <el-input v-model="form.typeStr" placeholder="请输入 融媒体类型" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="优先级" prop="num" :rules="[{ required: true, message: '请输入 优先级' }]">
              <el-input-number v-model="form.num" :min="1" :max="10000"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="融媒体描述" prop="msg">
              <el-input clearable v-model="form.msg" placeholder="请输入 融媒体描述" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="editSave">提交</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog append-to-body v-model="viodeVisible" :before-close="viodeClose" title="查看媒体" width="30%">
      <video id="myVideo" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto" data-setup='{}' style='width: 100%;'>
        <source id="source" src="" type="application/x-mpegURL" />
      </video>
    </el-dialog>
  </div>
</template>
<script>
import { dataList, addData, editData, delData } from "@/api/popularization/financialmedia";
import { communityAll } from "@/api/popularization/build";
import Searchbar from '@/components/Searchbar';
import { ElMessage, ElMessageBox } from "element-plus";
import videojs from "video.js"
export default {
  components:{
    Searchbar,
  },
  data(){
    return{
      myVideo:null,
      viodeVisible:false,
      viodeUrl:"",
      buildList:[],
      communityList:[],
      editFormVisible:false,
      title:"",
      dialogFormVisible: false,
      form:{},
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "mediaName",
          label: "融媒体名称",
          render: (row) => <div>{row.mediaName}</div>,
        },
        {
          prop: "msg",
          label: "描述",
          render: (row) => <div>{row.msg}</div>,
        },
        {
          prop: "typeStr",
          label: "类型",
          render: (row) => <div>{row.typeStr}</div>,
        },
        {
          prop: "addTime",
          label: "添加时间",
          render: (row) => <div>{row.addTime}</div>,
        },
        {
          prop: "addUserName",
          label: "添加用户",
          render: (row) => <div>{row.addUserName}</div>,
        },
        {
          prop: "upTime",
          label: "修改时间",
          render: (row) => <div>{row.upTime}</div>,
        },
        {
          prop: "upUserName",
          label: "修改用户",
          render: (row) => <div>{row.upUserName}</div>,
        },
        {
          prop: "",
          width: 300,
          label: "编辑",
          render: (row) => (
              <div>
                <el-button type="text" icon="VideoPlay" onClick={()=>this.showViode(row)}>
                  查看媒体
                </el-button>
                <el-button type="text" icon="Edit" onClick={()=>this.dataEdit(row)}>
                  编辑
                </el-button>
                <el-button type="text" icon="Delete" onClick={()=>this.del(row)}>
                  删除
                </el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  watch:{
    "form.community_code"(n,o){
      if(!n) return;
      getLouDongAll({ community_code: n}).then(res=>{
        this.buildList = res.data.data;
      })
    }
  },
  created() {
    this.onLoad();
    communityAll().then(res=>{
      this.communityList = res.data.data;
    })
  },
  methods:{
    viodeClose(done){
      done()
      this.myVideo.pause();
    },
    showViode(row){
      this.viodeVisible = true;
      this.$nextTick(()=>{
        this.myVideo = videojs('myVideo', {
          bigPlayButton: true,
          textTrackDisplay: false,
          posterImage: false,
          errorDisplay: false,
        })
        this.myVideo.src({src: row.mediaAddr})
        this.myVideo.play();
      })
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    onSearch(){
      this.page.current = 1;
      this.onLoad();
    },

    resetForm() {
      this.searchForm = {};
      this.onLoad();
    },

    del(row){
      ElMessageBox.confirm(
          '此操作将永久删除该数据, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        delData({id:row.id}).then(res=>{
          ElMessage({message: res.data.code.message, type: 'success'});
          this.page.current = 1;
          this.page.limit = 10;
          this.searchForm = {};
          this.onLoad();
        })
      }).catch(() => {})
    },
    editSave(){
      this.$refs.dataform.validate((valid) => {
        if (valid) {
          let {id,mediaName, mediaAddr, num, msg, typeStr } = this.form;
          editData({
            id, mediaName, mediaAddr, num, msg, typeStr
          }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              this.editFormVisible = false;
              this.onLoad();
            }
          })
        } else {
          return false;
        }
      });
    },

    dataEdit(row){
      this.form = JSON.parse(JSON.stringify(row));
      this.editFormVisible = true;
    },
    onSave(){
      this.$refs.dataform.validate((valid) => {
        if (valid) {
          let { mediaName, mediaAddr, num, msg, typeStr } = this.form;
          addData({
            mediaName, mediaAddr, num, msg, typeStr
          }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              this.dialogFormVisible = false;
              this.page.current = 1;
              this.page.limit = 10;
              this.searchForm = {};
              this.onLoad();
            }
          })
        } else {
          return false;
        }
      });
    },
    onLoad(){
      dataList({ limit:this.page.limit, page:this.page.current, ...this.searchForm }).then(res=>{
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>