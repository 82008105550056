import axios from "@/api/axios"; // 引用axios

export const dataList = (params) => {
    return axios({
        url: "/api/financialMedia/listData",
        method: "post",
        params: params,
    });
};

export const addData = (params) => {
    return axios({
        url: "/api/financialMedia/addData",
        method: "post",
        params: params,
    });
};


export const editData = (params) => {
    return axios({
        url: "/api/financialMedia/editData",
        method: "post",
        params: params,
    });
};


export const delData = (params) => {
    return axios({
        url: "/api/financialMedia/del",
        method: "post",
        params: params,
    });
};
