<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="小区名称">
            <el-input v-model="searchForm.community_name" placeholder="请输入 小区名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="小区编号">
            <el-input v-model="searchForm.community_code" placeholder="请输入 小区编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="楼栋编码">
            <el-input v-model="searchForm.code" placeholder="请输入 楼栋编码"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
<!--        <el-button @click="dialogFormVisible = true; title = '新增'" class="searchBtn">新增</el-button>-->
      </template>
    </Searchbar>

    <basicTable
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        title="详情"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <div class="tab">
        <span style="font-size: 28px;padding: 10px;">发起设备详情</span>
        <article>
          <section>所属小区</section>
          <section>{{form.otherInfo.community_name}}</section>
        </article>
        <article>
          <section>小区资源码</section>
          <section>{{form.otherInfo.community_code}}</section>
        </article>
        <article>
          <section>物理码</section>
          <section>{{form.otherInfo.mac}}</section>
        </article>
        <article>
          <section>资源码</section>
          <section>{{form.otherInfo.code}}</section>
        </article>
        <article>
          <section>楼栋编码</section>
          <section>{{form.otherInfo.loudong_code}}</section>
        </article>
        <article>
          <section>类型</section>
          <section>{{form.otherInfo.type == 1 ? "普通用户家庭终端" : "物业使用终端"}}</section>
        </article>
      </div>

      <div class="tab">
        <span style="font-size: 28px;padding: 10px;">接收终端</span>
        <article>
          <section>物理码</section>
          <section>{{form.terminalInfo.mac}}</section>
        </article>
        <article>
          <section>资源码</section>
          <section>{{form.terminalInfo.code}}</section>
        </article>
        <article>
          <section>所属小区</section>
          <section>{{form.terminalInfo.community_name}}</section>
        </article>
        <article>
          <section>小区资源码</section>
          <section>{{form.terminalInfo.community_code}}</section>
        </article>
        <article>
          <section>类型</section>
          <section>{{form.terminalInfo.type == 1 ? "普通用户家庭终端" : "物业使用终端"}}</section>
        </article>
      </div>

      <div class="tab">
        <span style="font-size: 28px;padding: 10px;">流程记录</span>
        <article v-for="(item, index) in form.process" :key="index">
          <section>{{item.time}}</section>
          <section>{{item.msg}}</section>
        </article>
      </div>

      <div class="tab">
        <span style="font-size: 28px;padding: 10px;">其他</span>
        <article>
          <section>发起时间</section>
          <section>{{form.addTime}}</section>
        </article>
        <article>
          <section>调用社区时间</section>
          <section>{{form.callTime}}</section>
        </article>
        <article>
          <section>家庭用户</section>
          <section>{{form.sqUserIds}}</section>
        </article>
      </div>
<!--      <el-form-->
<!--          label-position="right"-->
<!--          :model="form"-->
<!--          size="small"-->
<!--          label-width="120px"-->
<!--          ref="dataform"-->
<!--      >-->
<!--        <el-row :gutter="20">-->
<!--          <el-col :span="24">-->
<!--            <span style="font-size: 28px;padding: 10px;">发起设备详情</span>-->
<!--          </el-col>-->
<!--          <el-col :span="12">-->
<!--            <el-form-item label="所属小区" prop="community_name">-->
<!--              <span>{{form.otherInfo.community_name}}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="12">-->
<!--            <el-form-item label="小区资源码" prop="community_code">-->
<!--              <span>{{form.otherInfo.community_code}}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="12">-->
<!--            <el-form-item label="物理码" prop="mac">-->
<!--              <span>{{ form.otherInfo.mac }}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="资源码" prop="code">-->
<!--              <span>{{ form.terminalInfo.code }}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="楼栋编码" prop="loudong_code">-->
<!--              <span>{{form.otherInfo.loudong_code}}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="房屋编号" prop="house_code">-->
<!--              <span>{{form.otherInfo.house_code}}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="类型" prop="type">-->
<!--              <span>{{form.otherInfo.type == 1 ? "普通用户家庭终端" : "物业使用终端"}}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->


<!--          <el-col :span="24">-->
<!--            <span style="font-size: 28px;padding: 10px;">接收终端</span>-->
<!--          </el-col>-->
<!--          <el-col :span="12">-->
<!--            <el-form-item label="物理码" prop="resource_mac">-->
<!--              <span>{{ form.terminalInfo.mac }}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="资源码" prop="code">-->
<!--              <span>{{ form.terminalInfo.code }}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="所属小区" prop="community_name">-->
<!--              <span>{{ form.terminalInfo.community_name }}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="小区资源码" prop="community_code">-->
<!--              <span>{{ form.terminalInfo.community_code }}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="类型" prop="type">-->
<!--              <span>{{form.terminalInfo.type == 1 ? "普通用户家庭终端" : "物业使用终端"}}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->


<!--          <el-col :span="24">-->
<!--            <span style="font-size: 28px;padding: 10px;">流程记录</span>-->
<!--          </el-col>-->
<!--          <div v-for="(item,index) in form.process" :key="index">-->
<!--            <el-col :span="10">-->
<!--              <el-form-item label="时间" prop="time">-->
<!--                <div>{{item.time}}</div>-->
<!--              </el-form-item>-->
<!--            </el-col>-->

<!--            <el-col :span="14">-->
<!--              <el-form-item label="描述" prop="msg">-->
<!--                <div>{{item.msg}}</div>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </div>-->


<!--          <el-col :span="24">-->
<!--            <span style="font-size: 28px;padding: 10px;">其他</span>-->
<!--          </el-col>-->
<!--          <el-col :span="12">-->
<!--            <el-form-item label="发起时间" prop="addTime">-->
<!--              <span>{{form.addTime}}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="调用社区时间" prop="callTime">-->
<!--              <span>{{form.callTime}}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="12">-->
<!--            <el-form-item label="家庭用户" prop="sqUserIds">-->
<!--              <span>{{form.sqUserIds}}</span>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-form>-->
    </el-dialog>
  </div>
</template>
<script>
import { dataList } from "@/api/popularization/callRecord";
import Searchbar from '@/components/Searchbar';
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components:{
    Searchbar,
  },
  data(){
    return{
      dialogFormVisible:false,
      form:{},
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "communityCode",
          label: "小区编码",
          render: (row) => <div>{row.communityCode}</div>,
        },
        {
          prop: "reporter",
          label: "发起设备",
          render: (row) => <div>{row.reporter}</div>,
        },
        {
          prop: "addTime",
          label: "发起时间",
          render: (row) => <div>{row.addTime}</div>,
        },
        {
          prop: "mac",
          label: "发起设备物理码",
          render: (row) => <div>{row.mac}</div>,
        },
        {
          prop: "propertyMac",
          label: "管理设备物理码",
          render: (row) => <div>{row.propertyMac}</div>,
        },
        {
          prop: "",
          label: "编辑",
          render: (row) => (
              <div>
                <el-button type="text" icon="Edit" onClick={()=>this.showxq(row)}>
                  详情
                </el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  watch:{
  },
  created() {
    this.onLoad();
  },
  methods:{
    showxq(row){
      this.form.addTime = row.addTime;
      this.form.callTime = row.callTime;
      this.form.sqUserIds = row.sqUserIds;
      this.form.otherInfo = JSON.parse(row.otherInfo);
      this.form.terminalInfo = JSON.parse(row.terminalInfo);
      this.form.process = row.process.split('B-H').map(item=>JSON.parse(item));
      this.dialogFormVisible = true;
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    onSearch(){
      this.page.current = 1;
      this.onLoad();
    },

    resetForm() {
      this.searchForm = {};
      this.onLoad();
    },


    onLoad(){
      dataList({ limit:this.page.limit, page:this.page.current, ...this.searchForm }).then(res=>{
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tab{
  width: 100%;
  display: flex;
  flex-direction: column;
  >article{
    font-size: 20px;
    //padding: 0 50px;
    border: 1px solid;
    border-bottom: none;
    display: flex;
    align-items: center;
    &:last-child{
      border-bottom: 1px solid;
    }
    section{
      padding: 10px;
      &:nth-child(1){
        width: 140px;
        text-align: center;
      }
      &:nth-child(2){
        border-left: 1px solid;
        width: calc(100% - 140px);
        text-align: left;
      }
    }
  }
}
</style>