<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="小区名称">
            <el-select clearable v-model="searchForm.serial" placeholder="请选择 小区" size="large">
              <el-option v-for="item in communityList" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="终端物理码">
            <el-input v-model="searchForm.mac" placeholder="请输入 终端物理码"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>

      </template>
    </Searchbar>

    <basicTable
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog v-model="dialogVisible" title="反馈信息" width="30%">
      <span style="font-size: 20px">{{dialogform.feedbackInfo || '暂无反馈信息'}}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { communityAll } from "@/api/popularization/build";
import { dataList } from "@/api/popularization/feedback";
import Searchbar from '@/components/Searchbar';
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components:{
    Searchbar,
  },
  data(){
    return{
      communityList:[],
      dialogform:{},
      dialogVisible:false,
      form:{},
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "name",
          label: "小区名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "serial",
          label: "小区区域码",
          render: (row) => <div>{row.serial}</div>,
        },
        {
          prop: "family",
          label: "设备家庭信息",
          render: (row) => <div>{row.family}</div>,
        },
        {
          prop: "addTime",
          label: "添加时间",
          render: (row) => <div>{row.addTime}</div>,
        },
        {
          prop: "tellPhone",
          label: "联系电话",
          render: (row) => <div>{row.tellPhone}</div>,
        },
        {
          prop: "mac",
          label: "终端物理码",
          render: (row) => <div>{row.mac}</div>,
        },

        {
          prop: "",
          label: "编辑",
          render: (row) => (
              <div>
                <el-button type="text" icon="View" onClick={() => this.showFeed(row)}>反馈信息</el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  mounted() {
    communityAll().then(res=>{
      this.communityList = res.data.data;
    })
  },
  watch:{
  },
  created() {
    this.onLoad();
  },
  methods:{
    showFeed(row){
      this.dialogVisible = true;
      this.dialogform = row;
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    onSearch(){
      this.page.current = 1;
      this.onLoad();
    },

    resetForm() {
      this.searchForm = {};
      this.onLoad();
    },


    onLoad(){
      dataList({ limit:this.page.limit, page:this.page.current, ...this.searchForm }).then(res=>{
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tab{
  width: 100%;
  display: flex;
  flex-direction: column;
  >article{
    font-size: 20px;
    //padding: 0 50px;
    border: 1px solid;
    border-bottom: none;
    display: flex;
    align-items: center;
    &:last-child{
      border-bottom: 1px solid;
    }
    section{
      padding: 10px;
      &:nth-child(1){
        width: 140px;
        text-align: center;
      }
      &:nth-child(2){
        border-left: 1px solid;
        width: calc(100% - 140px);
        text-align: left;
      }
    }
  }
}
</style>