<template>
  <div class="main">
    <div id="container" style="width: 100%;height: 900px"></div>
    <div class="data">
      <el-select style="width: 300px;" clearable v-model="searchForm.tcCode" placeholder="请选择 小区" size="large">
        <el-option v-for="item in communityList" :key="item.code" :label="item.name" :value="item.code">
        </el-option>
      </el-select>
      <div>设备总数：{{dataNum.sum}}</div>
      <div>设备在线数：{{dataNum.on_line}}</div>
    </div>

    <el-dialog append-to-body v-model="dialogVisible" title="小区详情" width="49%" :before-close="handleClose">
        <el-form :model="form" status-icon ref="form" label-width="100px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="小区名称">
                <span>{{form.name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资源编码">
                <span>{{form.code}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属区域">
                <span>{{form.region_name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="区域码">
                <span>{{form.region_code}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="编号">
                <span>{{form.serial}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经度">
                <span>{{form.longitude}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度">
                <span>{{form.latitude}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间">
                <span>{{form.create_time}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="更新时间">
                <span>{{form.update_time || '暂无更新时间'}}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>


      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { communityAll } from "@/api/popularization/build";
import { dataList } from "@/api/popularization/statistics";
export default {
  data(){
    return{
      form:{},
      dialogVisible:false,
      searchForm:{},
      communityList:[],
      dataNum:{}
    }
  },
  watch:{
    "searchForm.tcCode"(n){
      let obj = {};
      n ? obj = { community_code: n } : obj= {};
      dataList({ ...obj }).then(res=>{
        this.dataNum = res.data.data;
      })
    }
  },
  mounted() {
    dataList().then(res=>{
      this.dataNum = res.data.data;
    })
    communityAll().then(res=>{
      this.communityList = res.data.data;
      let map = new AMap.Map("container", {
        resizeEnable: true,
        dragEnable: true,
        doubleClickZoom: true,
        rotateEnable: true,
        mapStyle: "amap://styles/924a0b80c02969b8a905eab4dd9f5431",
        center: [104.065735, 30.659462]
      });
      res.data.data.forEach(item=>{
        let marker = new AMap.Marker({
          title: item.name,
          position: [item.longitude,item.latitude],//位置
          icon: new AMap.Icon({
            image: require('../../assets/houseIcon.png'),
            size: new AMap.Size(52, 52),
            imageSize: new AMap.Size(30, 32)//图标大小
          })
        })
        marker.extData = item;
        marker.on('click', (mk)=> {
          this.dialogVisible = true;
          this.form = mk.target.extData;
        });
        map.add(marker)
      })
    })
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-select{
  height: 0% !important;
  width: 300px !important;
}
.main{
  position: relative;
  .data{
    width: 100%;
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    >div{
      margin-left: 20px;
      font-size: 30px;
      color: #FFFFFF;
    }
  }
}
</style>